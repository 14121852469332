import { TopPosterModel } from 'models/body/TopPosterModel'
import React, { Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import Fade from 'react-reveal/Fade'
import cookie from 'js-cookie'
import Markdown from 'markdown-to-jsx'
import { Dialog, Transition } from '@headlessui/react'

type Props = {
  data: TopPosterModel
}

const TopPoster = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const cancelButtonRef = useRef(null)
  // const hasCookie = cookie.get('poster-closed')
  const closePoster = () => {
    // cookie.set('poster-closed', 'true')
    setIsOpen(false)

    setTimeout(()=> {
      const anyHero = document.getElementById('any-hero');
      const headerContainer = document.getElementById('header-container');
      
      if (anyHero !== undefined) {
        // @ts-ignore
        anyHero.style.paddingTop = `${headerContainer?.clientHeight}px`;
      }
    }, 1000);
  }

  // useEffect(() => {
  //   const hasCookie = cookie.get('poster-closed')
  //   if (hasCookie) {
  //     setIsOpen(false)
  //   }
  //   return () => {}
  // }, [])

  return (
    <Fragment>
      <Transition
        show={isOpen}
        enter='transition-all duration-300'
        enterFrom='-translate-y-8 opacity-0'
        enterTo='-translate-y-0 opacity-100'
        leave='transition-all duration-300'
        leaveFrom='-translate-y-0 opacity-100'
        leaveTo='-translate-y-8 opacity-0'
      >
        <div className="top-sticky relative px-6">
          <div className='w-full px-[30px] text-center py-[14px] flex flex-wrap gap-x-10 gap-y-5 justify-center items-center relative'>
            <div className='promo-content text-center text-white text-lg'>
              <Markdown>{props.data.attributes?.title}</Markdown>
            </div>
            {props.data.attributes.buttonText && 
              props.data.attributes.showModalOnButtonClick ? (
                <>
                  <a
                    className="cursor-pointer font-Inter inline-block rounded-full bg-[#E91BA7] px-6 py-[6px] text-center text-base font-bold text-white duration-300 ease-in hover:opacity-90 xxl:mt-0"
                    onClick={() => setOpenModal(true)}
                    rel="noreferrer"
                  >
                    {props.data.attributes?.buttonText}
                  </a>
                </>
              )  : (
                <>
                  <a
                    className="cursor-pointer inline-block rounded-full bg-white px-6 py-[6px] text-center text-base font-bold text-[#8264EB] duration-300 ease-in hover:opacity-90 xxl:mt-0"
                    href={props.data.attributes?.buttonLink}
                    rel="noreferrer"
                  >
                    {props.data.attributes?.buttonText}
                  </a>
                </>
              )
            }
          </div>
          <button onClick={closePoster} className="popup-close text-white absolute right-5 top-[14px] text-lg font-bold">
            X
          </button>
        </div>
      </Transition>
      {props.data.attributes?.showModalOnButtonClick &&
        <Transition.Root show={openModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpenModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[680px]">
                    <div className="bg-white promo-modal-wrap">
                      <div className='promo-modal-content'>
                        <Markdown>{props.data.attributes?.modalContent}</Markdown>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      }
    </Fragment>
  )
}

export default TopPoster
