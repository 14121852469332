import TopPoster from 'components/body/TopPoster'
import MainMenu from 'components/layout/MainMenu'
import TopMenuBar from 'components/layout/TopMenuBar'
import { TopPosterModel } from 'models/body/TopPosterModel'
import { MenuModel } from 'models/menu/MenuModel'
import React from 'react'

type Props = {
  menu: MenuModel
  posters: {
    data: TopPosterModel[]
  }
  isStatic?: boolean
}

function HeaderContainer(props: Props) {
  return (
    <div id='header-container' className='fixed z-[9999] w-full'>
      {!!props.posters.data.length && 
        <>
          {props.posters.data.map((poster, index) => (
            <TopPoster key={index} data={poster} />
          ))}
        </>
      }
      <TopMenuBar />
      <MainMenu menu={props.menu} isStatic={props.isStatic}/>
    </div>
  )
}

export default HeaderContainer