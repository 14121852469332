import { SeoModel } from 'models/seo/SeoModel'
import Head from 'next/head'
import React, { Fragment, FunctionComponent } from 'react'
import { NextSeo } from 'next-seo'
import { GenerateImageLink } from 'utils/GenerateImageLink'

type Props = {
  seo: SeoModel
  title: string
}

const PageSeo: FunctionComponent<Props> = (props) => {
  if (!props.seo) {
    return null
  }
  return (
    <Fragment>
      <NextSeo
        title={props.title}
        description={props.seo.description}
        openGraph={{
          images: props.seo.metaImage ? [{ url: GenerateImageLink(props.seo.metaImage) }] : [],
        }}
      />
      <Head>
        {props.seo.metaAttributes.map((meta, index) => (
          <meta key={index} name={meta.name} content={meta.content} />
        ))}
      </Head>
    </Fragment>
  )
}

export default PageSeo
