import axios from 'axios'
import { useRouter } from 'next/router'
import React from 'react'

const PreviewNotice = () => {
  const router = useRouter()
  const exitPreview = async () => {
    const resp = await axios({
      method: 'GET',
      baseURL: '/api',
      url: '/exit-preview',
    })

    if (resp) {
      router.push('/')
    }
  }
  return (
    <div className="z-[60] fixed bottom-0 left-0 w-screen bg-white rounded-md shadow border-t border-gray-500 h-20 text-lg font-bold">
      <div className="w-11/12 h-full lg:w-10/12 mx-auto flex justify-between items-center">
        <h2 className="text-xl font-semibold">You are in preview mode</h2>
        <button onClick={exitPreview} className="btn-orange">
          Exit Preview
        </button>
      </div>
    </div>
  )
}

export default PreviewNotice
